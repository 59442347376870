.btn {
	display: inline-block;
	padding: $btn-padding-y $btn-padding-x;
	font-family: $btn-font-family;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	color: $btn-color;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	border: $btn-border-width solid $btn-border-color;
	border-radius: $btn-border-radius;
	background-color: $btn-bg;
	box-shadow: none;
	transition: $btn-transition;

	&:hover {
		color: $btn-hover-color;
		text-decoration: none;
		background-color: $btn-hover-bg;
		border-color: $btn-hover-border-color;
	}

	&:focus-visible {
		//color: $btn-hover-color;
		//background-color: $btn-hover-bg;
		//border-color: $btn-hover-border-color;
		outline: 0;
		// Avoid using mixin so we can pass custom focus shadow properly
		box-shadow: $btn-focus-visible-shadow;
	}

	&:active,
	&.active,
	&.show {
		color: $btn-active-color;
		background-color: $btn-active-bg;
		border-color: $btn-active-border-color;
		box-shadow: $btn-active-shadow;

		&:focus-visible {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: $btn-active-shadow $btn-focus-visible-shadow;
		}
	}
	&:disabled,
	&.disabled,
	fieldset:disabled & {
		//color: $btn-disabled-color;
		//pointer-events: none;
		//background-color: $btn-disabled-bg;
		//border-color: $btn-disabled-border-color;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}
}

.btn-primary {
	color: $body-color;
	background-color: $primary;
	border-color: $primary;
	&:hover {
		color: $white;
		background-color: $gray-darker;
		border-color: $gray-darker;
	}
}
.btn-primary-dark {
	color: $white;
	background-color: $primary-dark;
	border-color: $primary-dark;
	&:hover {
		color: $white;
		background-color: $gray-darker;
		border-color: $gray-darker;
	}
}

.btn-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: $secondary-dark;
		border-color: $secondary-dark;
	}
}

.btn-light {
	color: $gray-darker;
	background-color: $white;
	border-color: $gray-darker;
	&:hover {
		color: $white;
		background-color: $primary-dark;
		border-color: $primary-dark;
	}
}

.btn-skip {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.btn-signup {
	@extend .btn-primary-dark;
	font-size: 16px;
	line-height: 1.4375;
	letter-spacing: .16px;
	padding: 9px 32px;
	border-radius: 3rem;
	&:focus-visible {
		border-radius: 3rem;
	}
}

.btn-search {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	font-size: 1.25em;
	color: $gray-darker;
	background-color: $primary;
	border-radius: 50%;
	svg {
		width: 1em;
	}
	&:hover {
		color: $white;
		background-color: $gray-darker;
	}
	&:focus-visible {
		border-radius: 50%;
	}
}
.btn-scroll-top {
	position: absolute;
	right: $grid-gutter-width;
	margin-top: -26px;
	font-size: 1rem;
	font-weight: 700;
	padding: 14px;
	color: $white;
	background-color: $primary-dark;
	border: 1px solid $white;
	border-radius: $radius-sm;
	svg {
		margin-left: 12px;
		vertical-align: -.35em;
	}
	&:hover {
		color: $white;
		background-color: $gray-darker;
		border-color: $white;
	}
	&:focus-visible {

	}
}