:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.join-section {
  padding: 40px 0;
}
.join-section .section-title {
  margin-bottom: 40px;
}
.join-section .section-text {
  margin-bottom: 48px;
}
.join-section .col-cards .card-team {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
}
.join-section .btn-holder .btn {
  min-width: 200px;
}
@media (max-width: 767.98px) {
  .join-section .col-cards {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  .join-section {
    padding: 64px 0;
  }
  .join-section .section-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }
  .single-member .join-section .section-inner {
    max-width: 972px;
    margin-left: auto;
    margin-right: auto;
  }
  .single-member .join-section .card-team:only-child {
    margin-right: 0;
  }
  .join-section .col-text,
  .join-section .col-cards {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 0;
  }
  .join-section .col-cards .card-team {
    max-width: 342px;
  }
  .join-section .section-title {
    margin-bottom: 52px;
  }
  .join-section .section-text {
    font-size: 22px;
    margin-bottom: 52px;
  }
  .join-section .card-team {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .join-section .col-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .join-section .col-cards .card-team {
    width: calc(50% - 16px);
  }
  .join-section .col-cards .card-team:nth-of-type(2n+2) {
    margin-left: 32px;
  }
  .single-member .join-section .col-cards .card-team {
    width: 100%;
  }
  .single-member .join-section .col-cards .card-team:nth-of-type(2n+2) {
    margin-left: auto;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}
