.mobile-hidden {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.desktop-hidden {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ratio-16x9 {
  padding-top: 56.25%;
}

.js-collapse {
  //.collapse-content {
  //  display: none;
  //}
  .text-hide {
    display: none;
  }
  &.is-open {
    //.collapse-content {
    //  display: block;
    //}
    .text-hide {
      display: block;
    }
    .text-show {
      display: none;
    }
  }
}