@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/maps';

@import '../../../src/scss/components/vars';
@import '../../../src/scss/components/mixins';

.error404 {
  @include media-breakpoint-up(md) {
    .join-section {
      padding: 108px 0;
    }
  }
}

.hero-404-section {
  background-color: $white;
  padding: 40px 0;
  .hero-title {
    letter-spacing: -.5px;
    line-height: 1.2;
    margin-bottom: 40px;
  }
  .hero-text {
    margin-bottom: 1.5rem;
  }
  .col-img {
    .img-holder {
      text-align: center;
    }
  }
  @include media-breakpoint-down(md) {
    .col-img {
      display: none;
    }
    .hero-title {
      font-size: 48px;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 120px 0;
    .section-inner {
      @include row;
      align-items: center;
    }
    .col-text,
    .col-img {
      @include col;
    }
    .col-img {
      .img-holder {
        text-align: right;
      }
    }
    .hero-title {
      margin-bottom: 64px;
    }
    .hero-text {
      font-size: 22px;
      line-height: 1.6;
      margin-bottom: 0;
    }
  }
}
