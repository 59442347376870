* {
	outline: none;
	box-sizing: border-box
}

body, html {
	height: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	margin: 0
}

body {
	position: relative;
	min-width: 300px;
	display: flex;
	height: auto;
	font-family: $ff;
	font-size: $fs-md;
	line-height: $lh;
	color: $body-color;
	background-color: $body-bg;
}

img {
	max-width: 100%;
	height: auto
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;

	.footer {
		margin-top: auto
	}
}

.container {
	width: 100%;
	max-width: $cont-max-width;
	padding-left: calc($grid-gutter-width / 2);
	padding-right: calc($grid-gutter-width / 2);
	margin-left: auto;
	margin-right: auto;
}

.section {
	padding: 40px 0;
	@include media-breakpoint-up(md) {
		padding: 64px 0;
	}
}

.section-6-col {
	@include media-breakpoint-up(md) {
		.section-inner {
			max-width: $width-6-col;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.section-8-col {
	@include media-breakpoint-up(lg) {
		.section-inner {
			max-width: $width-8-col;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.section-10-col {
	@include media-breakpoint-up(xl) {
		.section-inner {
			max-width: $width-10-col;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


/* STYLES FOR WP CONTENT */
.page-content {
	clear: both;
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}
.text-center {
	text-align: center;
}
.aligncenter {
	text-align: center;
}
.alignleft {
	text-align: left;
}
.alignright {
	text-align: right;
}
img.alignleft, .wp-caption.alignleft {
	float: left;
	margin-right: 15px;
}
img.alignright, .wp-caption.alignright {
	float: right;
	margin-left: 15px;
}
.wp-caption {
	max-width: 100%;
	&.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}
}
.wp-caption-text {
	font-family: $ff;
	font-size: 1rem;
	margin-top: 4px;
}
@include media-breakpoint-down(xs) {

	img.alignleft, .wp-caption.alignleft {
		float: none;
		margin-right: 0;
	}
	img.alignright, .wp-caption.alignright {
		float: none;
		margin-left: 0;
	}
}
/* END STYLES FOR WP CONTENT */


// TO DO:

.wp-block-image,
.wp-block-table,
.wp-block-embed {
	figcaption {
		font-size: .8em;
		font-style: italic;
		text-align: center;
	}
}
.wp-block-table {
	figcaption {
		margin-top: 0.5em;
		margin-bottom: 1em;
	}
}
.col-img {
	.img-holder {
		img {
			width: auto;
		}
	}
}