// Footer common styles.
.footer {
  position: relative;
  color: $white;
  background-color: $primary-darker;
}
// nav
.footer-navs {
  padding-top: 32px;
  padding-bottom: 32px;
  .footer-nav-title {
    font-family: $ff;
    font-size: 22px;
    line-height: 1.2;
    color: inherit;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.footer-navs-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 34px 32px;
  .footer-col {
    width: 100%;
  }
  nav {
    + nav {
      margin-top: 66px;
    }
  }
  @include media-breakpoint-up(sm) {
    .footer-col {
      width: calc(50% - 16px);
    }
  }
  @include media-breakpoint-up(lg) {
    .footer-col {
      flex: 1 0 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.footer-nav {
  @include list-unstyled;
  @include links-hover;
  ul {
    @include list-unstyled;
  }
  li {
    margin-bottom: .5rem;
    + .menu-item-has-children {
      margin-top: 54px;
    }
  }
  a {
    &:focus-visible {
      box-shadow: $focus-visible-inverse;
    }
  }
  .menu-item-has-children {
     > a {
       font-weight: 500;
       color: $white;
       margin-bottom: 1rem;
     }
  }
  a {
    color: $gray2;
    &:hover {
      color: $white;
    }
  }
}
// info
.footer-info {
  background-color: $primary-dark;
  padding-top: 32px;
  padding-bottom: 32px;
  .footer-info-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 32px;
  }
  .footer-col {
    width: 100%;
  }
  .brand-block {
    font-size: 20px;
    line-height: 1.5;
    color: $gray-lighter2;
  }
  .navbar-brand {
    width: 148px;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    .footer-info-inner {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
    }
    .footer-col {
      width: calc(50% - 16px);
      &.col-brand {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 64px;
    padding-bottom: 32px;
    .navbar-brand {
      margin-bottom: 32px;
    }
  }
  @include media-breakpoint-up(lg) {
    .footer-col {
      width: calc(25% - 24px);
      &.col-brand {
        width: calc(50% - 16px);
      }
    }
  }
}

.contact-block,
.follow-block {
  color: $gray-lighter2;
  .footer-title {
    font-size: 32px;
    color: $white;
    padding-bottom: 16px;
    border-bottom: 1px solid;
    margin-bottom: 16px;
  }
  @include media-breakpoint-up(md) {
    .footer-title {
      padding-bottom: 32px;
      margin-bottom: 32px;
    }
  }
}
.contact-block {
  .address {
    .address-item {
      margin-bottom: 1rem;
      a {
        display: flex;
        align-items: center;
        &:hover {
          .item-icon {
            filter: brightness(1.5);
          }
        }
      }
    }
    .item-address {
      font-size: 1rem;
    }
    .item-icon {
      width: 100%;
      max-width: 40px;
      margin-right: 1rem;
      transition: opacity $tr-anim;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      .address-item {
        margin-bottom: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.follow-block {
  @include media-breakpoint-down(sm) {
    margin-top: 24px;
    .footer-title {
      padding-bottom: 8px;
    }
  }
}

// bottom
.footer-legal {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $primary-darkest;
  .footer-nav-legal,
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.footer-legal-inner {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(sm) {
    .footer-nav-legal {
      margin-right: 48px;
    }
  }
}
.footer-nav-legal {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  gap: 18px 24px;
  @include links-hover;
  @include media-breakpoint-down(sm) {
    li {
      width: calc(50% - 12px);
    }
  }
}
.social-list {
  @include list-unstyled;
  margin-bottom: 0;
  li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    margin-bottom: 1rem;
  }
  a {
    transition: filter $tr-anim;
    &:hover {
      filter: brightness(1.5);
    }
    &:focus-visible {
      box-shadow: $focus-visible-inverse;
    }
  }
}