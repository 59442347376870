@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.subscribe-section {
  color: $white;
  background-color: $secondary-dark;
  padding: 32px 0;
  .section-title {
    color: inherit;
    margin-bottom: 48px;
  }
  .section-text {
    margin-bottom: 3rem;
  }
  .img-holder {
    text-align: center;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
  .col-text {
    .img-holder {
      margin-bottom: 1.75rem;
    }
  }
  @include media-breakpoint-down(md) {
    .col-img {
      display: none;
    }
    .btn-holder {
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: $margin-lg 0;
    .section-inner {
      @include row;
      align-items: center;
    }
    .col-text,
    .col-img {
      @include col;
    }
    .col-text {
      .img-holder {
        display: none;
      }
    }
    .section-title {
      font-size: 32px;
      line-height: 1.4;
      letter-spacing: 0;
    }
    .home & {
      .section-title {
        font-size: 32px; // to specify
      }
    }
    .section-text {
      font-size: 20px;
      line-height: 1.6;
    }
  }
}
