.breadcrumbs {
	font-size: 14px;
	padding: 6px 0 7px;
	background-color: $gray-lightest2;
	border-bottom: 1px solid rgba($primary-dark,.1);
	.breadcrumbs-sep {
		margin: 0 4px;
	}
	.current-item {
		font-weight: 700;
	}
	@include media-breakpoint-up(md) {
		padding: 1.375rem 0;
		.breadcrumbs-sep {
			margin: 0 12px;
		}
	}
}
