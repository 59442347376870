// Header common styles.
.header {
  min-height: 82px;
  background-color: $white;
  border-bottom: 1px solid $gray;
  @include media-breakpoint-up($nav-bp) {
    padding-top: 37px;
    border-color: $gray-lightest;
  }
}
.navbar-top {
  position: relative; // for search form
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  .navbar-signup,
  .navbar-search,
  .navbar-toggler {
    margin-left: auto;
  }
  .navbar-signup {
    + .navbar-search {
      margin-left: 48px;
    }
  }
  .navbar-search {
    + .navbar-toggler {
      margin-left: 16px;
    }
  }
  .navbar-brand {
    height: 50px;
    margin-right: auto;
    img {
      height: 100%;
      width: auto;
    }
  }
  @include media-breakpoint-down(md) {
    .navbar-signup {
      display: none;
    }
  }
  @include media-breakpoint-up($nav-bp) {
    padding-top: 25px;
    padding-bottom: 25px;
    .navbar-toggler {
      display: none;
    }
    .search-toggler {
      width: 64px;
      height: 64px;
    }
    .navbar-brand {
      height: 95px;
      margin-left: 44px; // for equal top and left space
    }
  }
}
.navbar-brand {
  img {
    transition: filter $tr-anim;
  }
  &:hover {
    svg {
      path:first-of-type {
        fill: #B32974;
      }
    }
    img {
      filter: saturate(0.5) contrast(1.3);
    }
  }
}
.search-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: $body-color;
  background-color: $primary;
  border: 1px solid $white;
  transition: color $tr-anim, background-color $tr-anim;
  svg {
    width: 50%;
  }
  &:hover {
    color: $white;
    background-color: $body-color;
  }
  &:focus-visible {
    border-radius: 50%;
  }
}

.search-form-block {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 13px 0;
  //background-color: rgba($dark-purple, .8);
  //background-color: red;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 1002;

  &.is-shown {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}
.modal-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, .8);
  z-index: $zindex-search;
}
.search-form-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 822px;
  margin-left: auto;
  gap: 12px;
  padding: 3px;
  color: $white;
  background-color: rgba($black, .25);
  border: 1px solid $search-block-border-mobile;
  border-radius: 3rem;
  .search-form-text {
    font-size: 12px;
    padding-left: .5rem;
    p {
      margin-bottom: 0;
    }
  }
  .search-form-holder {
    position: relative;
    flex-grow: 1;
    .btn-search {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      right: 4px;
    }
  }

  .form-group-search {
    .form-control {
      &:focus {
        box-shadow: $box-shadow;
        border-color: $search-border-focus;
      }
    }
  }
  .btn-search-close {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    color: $white;
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
    border-radius: 50%;
    transition: background-color $tr-anim;
    &::before,
    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 32px;
      height: 3px;
      background-color: $white;
      margin-top: -1px;
      margin-left: -16px;
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover {
      color: $primary-dark;
      background-color: $white;
      &::before,
      &::after {
        background-color: $body-color;
      }
    }
  }
  @include media-breakpoint-up($nav-bp) {
    border: 2px solid $search-block-border;
    padding: 6px;
    gap: 16px;
    margin-top: 19px;
    margin-right: -4px;
    .search-form-text {
      font-size: 22px;
      font-weight: 700;
      padding-left: 1rem;
    }
    .btn-search {
      width: 48px;
      height: 48px;
      svg {
        width: 24px;
      }
    }
    .search-form-holder {
      .btn-search {
        right: 8px;
        margin-top: -24px;
      }
    }
    .form-group-search {
      .form-control {
        padding: 19px 64px 19px 24px;
      }
    }
    .btn-search-close {
      width: 64px;
      height: 64px;
      color: $body-color;
      background-color: $white;
      &::before,
      &::after {
        width: 40px;
        margin-left: -20px;
        background-color: $body-color;
      }
      &:hover {
        color: $white;
        background-color: $primary-dark;
        &::before,
        &::after {
          background-color: $white;
        }
      }
    }
  }
}

.form-group-search {
  input[type="submit"] {
    display: none;
  }
  .form-control {
    background-color: $white;
    border-color: $search-border;
    padding: 11px 16px;
    border-radius: 3rem;
  }
}

.navbar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 8px;
  color: $body-color;
  background-color: transparent;
  border: 1px solid $dark-purple;
  border-radius: $radius-sm;
  transition: background-color $tr-anim;
  .navbar-toggler-icon {
    position: relative;
    width: 24px;
    height: 3px;
    background-color: $dark-purple;
    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 3px;
      top: -9px;
      left: 0;
      content: '';
      background-color: inherit;
      transition: transform $tr-anim;
    }
    &::after {
      top: 9px;
    }
  }
  &:hover {
    background-color: $primary-dark;
    border-color: $primary-dark;
    .navbar-toggler-icon {
      background-color: $white;
    }
  }
  &:focus-visible {
    box-shadow: $focus-visible;
  }
  .menu-opened & {
    background-color: $primary-dark;
    border-color: $primary-dark;
    .navbar-toggler-icon {
      background-color: transparent;
      &::before,
      &::after {
        width: 34px;
        background-color: $white;
      }
      &::before {
        transform: rotate(45deg) translate(3px, 10px);
      }
      &::after {
        transform: rotate(-45deg) translate(3px, -10px);
      }
    }
  }
}