@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.join-section {
  padding: 40px 0;
  .section-title {
    margin-bottom: 40px;
  }
  .section-text {
    margin-bottom: 48px;
  }
  .col-cards {
    .card-team {
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .btn-holder {
    .btn {
      min-width: 200px;
    }
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        //width: 100%;
      }
    }
    .col-cards {
      margin-top: 60px;
    }
  }
  @include media-breakpoint-up(md) {
    //padding: 128px 0;
    padding: $margin-lg 0;
    .section-inner {
      @include row;
      .single-member & {
        max-width: $width-8-col;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .single-member & {
      .card-team {
        &:only-child {
          margin-right: 0;
        }
      }
    }
    .col-text,
    .col-cards {
      @include col;
    }
    .col-cards {
      .card-team {
        max-width: 342px;
      }
    }
    .section-title {
      margin-bottom: 52px;
    }
    .section-text {
      font-size: 22px;
      margin-bottom: 52px;
    }
    .card-team {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    .col-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .card-team {
        width: calc(50% - ($grid-gutter-width/2));
        &:nth-of-type(2n+2) {
          margin-left: $grid-gutter-width;
        }
        .single-member & {
          width: 100%;
          &:nth-of-type(2n+2) {
            margin-left: auto;
          }
        }
      }
    }
  }
}

