@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/maps';

@import '../../../src/scss/components/vars';
@import '../../../src/scss/components/mixins';

.card-team {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  margin: 1.5rem 0;
  .img-wrapper {
    width: 120px;
    height: 120px;
    background-color: $white;
    border: 1px solid $card-border;
    border-radius: 50%;
    box-shadow: $box-shadow-cards-team;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  .card-img {
    position: relative;
    padding-top: 20px;
    margin-bottom: -60px;
    + .card-body {
      padding-top: 72px;
    }
  }
  .card-body {
    flex-grow: 1;
    padding: 32px 32px 32px;
    background-color: $white;
    border: 1px solid $card-border;
    border-radius: $radius-xxl;
  }
  .card-text {
    + .address {
      margin-top: 1rem;
      margin-bottom: 0;
      padding-top: 1rem;
      border-top: 1px solid $card-border;
    }
  }
  .name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .address {
    .address-item {
      margin-bottom: .75rem;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: flex;
        align-items: center;
        &:hover {
          .item-icon {
            //filter: brightness(1.5);
          }
        }
      }
    }
    .item-icon {
      width: 100%;
      max-width: 32px;
      margin-right: 1rem;
      filter: brightness(.1);
      //transition: opacity $tr-anim;
    }
  }
}