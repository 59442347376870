// links hover in list
@mixin links-hover {
  a {
    text-decoration: none;
    &::after {
      display: block;
      width: 0;
      height: 1px;
      //background-color: $white;
      border-bottom: 1px solid; // border color will use the colour of link
      content: '';
      opacity: 0;
      transition: width $tr-anim, opacity $tr-anim;
    }
    &:hover {
      text-decoration: none;
      &::after {
        width: 100%;
        opacity: 1;
      }
    }
  }
}

//@mixin list-unstyled {
//  padding: 0;
//  list-style: none;
//}

@mixin row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc($grid-gutter-width / (-2));
  margin-right: calc($grid-gutter-width / (-2));
}

@mixin col {
  width: 100%;
  padding-left: calc($grid-gutter-width / 2);
  padding-right: calc($grid-gutter-width / 2);
  flex: 1 1 0;
}
