// Grid.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
);
//$container-max-widths: (
//	sm: 540px,
//	md: 720px,
//	lg: 960px,
//	xl: 1140px
//);
$grid-gutter-width	: 32px;
$cont-max-width		: 1504px;
$width-10-col		: 1216px;
$width-8-col		: 972px;
$width-7-col		: 877px;
$width-6-col		: 724px;
$width-quote		: 1132px;
$width-case-study-heading: 1060px;

$nav-bp				: xl;
$nav-cols-bp		: lg;

// Font families.
$ff				: "Roboto", sans-serif;
$ff-title		: "Roboto Slab", serif;

// Font sizes.
$fs-xs	: 12px;
$fs-sm	: 14px;
$fs-md	: 16px;
$fs-lg	: 18px;
$fs-xl	: 20px;

// Font weights.
$fw-light		: 300;
$fw-regular		: 400;
$fw-medium		: 500;
$fw-semibold	: 600;
$fw-bold		: 700;
$fw-extrabold	: 800;
$fw-black		: 900;

// Line heights.
$lh			: 1.4;
$lh-title	: 1.1;

// Colors.
$white	        : #fff;
$black	        : #000;
$gray-lightest	: #F2F2F2;
//$gray-lightest2	: #FBFAFF;
$gray-lightest2: #FBFBFB;
$gray-lightest3	: #F7F7F7;
$gray-lighter2	: #EAEAEA;
$gray-lighter3	: #E2E2E2;
$gray-light	    : #D1D1D1;
$gray-light2    : #D9D9D9;
$gray	    	: #909090;
$gray2			: #ACAAB2;
$gray3	    	: #6F6F6F;
$gray-darker	: #1D1D1D;
$dark-purple	: #110E19;

$primary		: #00CCCC;
$primary-dark	: #005666;
$primary-darker	: #013641;
$primary-darkest: #012B33;
$gray-darkest2	: #1E2626;
$secondary		: #FF0099;
$secondary-dark	: #993366;
$tertiary		: #E1BC1E;
$tertiary-border: #BEFAB9;
$tertiary-dark	: #A39161;
$pink			: #FF0099;
$pink-dark		: #993366;

$danger			: #DA0004;

$search-block-border		: #BEFAB9;
$search-block-border-mobile	: #453966;
$search-border				: #D2F4DC;
$search-border-focus		: #B4AD00;
$search-result-border		: #88A4A6;

$card-border	: #BFB8D8;
$card-shadow	: #a78dfc;

$body-color		: $gray-darker;
//$body-bg		: $gray-lightest3;
$body-bg		: $gray-lightest;

// Others.
$tr			: 0.3s;
$tr-anim	: 0.3s ease-in-out;
$tr-anim-xs	: 0.1s ease-in-out;
$radius-xs	: 4px;
$radius-sm	: 8px;
$radius     : 12px;
$radius-lg	: 16px;
$radius-xl	: 20px;
$radius-xxl	: 24px;

$margin-xxl	: 120px;
$margin-lg	: 64px;
// Override Bootstrap CSS variables for links
:root,
[data-bs-theme="light"]  {
	--bs-link-color-rgb: #1D1D1D;
	--bs-link-hover-color-rgb: #1D1D1D;
}

// Links.
//$btn-link-color				: $body-color;
//$btn-link-hover-color		: $body-color;

$focus-visible			: 0 0 0 2px $body-bg, 0 0 0 4px $primary-dark;
$focus-visible-inverse	: 0 0 0 2px $primary-dark, 0 0 0 4px $body-bg;

$box-shadow				: 0px 4px 4px 0px rgba($black, 0.25);
$box-shadow-cards		: 0px 8px 16px 0px rgba($black, 0.20);
$box-shadow-cards-desk	: 0px 4px 32px 0px rgba($black, 0.10);
$box-shadow-cards-team	: 0px 8px 16px 0px rgba($card-shadow, 0.20);
$box-shadow-alt			: 0 8px 16px 0 rgba(#00005A, .1);
$box-shadow-alt2		: 0 4px 16px 0 rgba(#00005A, .1);

// Buttons.
$btn-padding-y		: 18px;
$btn-padding-x		: 22px;
$btn-font-family	: $ff;
$btn-font-size		: 18px;
$btn-font-weight	: $fw-medium;
$btn-line-height	: 1.33334;
$btn-color			: $body-color;
$btn-border-width	: 1px;
$btn-border-color	: transparent;
$btn-border-radius	: $radius-xs;
$btn-bg				: transparent;
$btn-transition		: color $tr-anim, background-color $tr-anim, border-color $tr-anim, box-shadow $tr-anim;
$btn-hover-color	: $white;
$btn-hover-bg		: transparent;
$btn-hover-border-color		: transparent;
$btn-active-color			: transparent;
$btn-active-bg				: transparent;
$btn-active-border-color	: transparent;
$btn-disabled-opacity		: .65;
$btn-focus-visible-shadow	: $focus-visible;
$btn-active-shadow			: inset 0 3px 5px rgba($black, .125);

// Inputs.
$input-padding-y		: .9375rem;
$input-padding-x		: 1rem;

$input-padding-y-sm		: 12px;
$input-padding-x-sm		: 16px;
$input-font-size-sm		: 14px;
$input-padding-y-lg		: 22px;
$input-padding-x-lg		: 26px;
$input-font-size-lg		: $fs-xl;

$input-font-family		: $ff;
$input-font-size		: 1rem;
$input-font-weight		: $fw-regular;
$input-line-height		: 1.5;
$input-height-inner-half: add($input-line-height * .5em, $input-padding-y);
$input-color			: $body-color;
$input-bg				: rgba($gray-lightest,.4);
$input-border-width		: 1px;
$input-border-color		: $gray-lightest;
$input-border-radius	: $radius-sm;
$input-box-shadow		: none;
$input-transition		: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$component-active-color		: $white;
$component-active-bg		: $primary-dark;
//$input-focus-color		: $body-color;
//$input-focus-bg;
$input-btn-focus-color		: rgba($component-active-bg, .25);
$input-focus-width			: .2rem;
$input-focus-border-color	: $primary-dark;
$input-placeholder-color	: $gray2;
$input-disabled-bg			: $gray-light;
$input-disabled-border-color: $gray-lightest;


$form-label-font-size		: 20px;
$form-label-font-weight		: $fw-semibold;
$form-label-color			: $primary-dark;
$form-label-margin-bottom	: 1em;

// Custom forms

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter:                 1rem;
$custom-control-spacer-x:               1rem;
$custom-control-cursor:                 null;

$custom-control-indicator-size:         1.5rem;
$custom-control-indicator-bg:           transparent;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   none;
$custom-control-indicator-border-color: $primary-dark;
$custom-control-indicator-border-width: 2px;

$custom-control-label-color:            null;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray;

$box-shadow-inset			: inset 0 1px 2px rgba($black, .075);

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-color:        $primary-dark;
$custom-control-indicator-checked-bg:           $component-active-bg;
$custom-control-indicator-checked-disabled-bg:  rgba($gray, .5);
$custom-control-indicator-checked-box-shadow:   null;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

//$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-bg:            rgba($component-active-bg, 25%);
$custom-control-indicator-active-box-shadow:    null;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius:       $radius-sm;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
$custom-checkbox-indicator-icon-checked-dark:   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#1D1D1D' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");

$custom-checkbox-indicator-indeterminate-bg:           $white;
$custom-checkbox-indicator-indeterminate-color:        $primary-dark;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow:   null;
$custom-checkbox-indicator-indeterminate-border-color: $primary-dark;

$custom-radio-indicator-border-radius			: 50%;
//$custom-radio-indicator-icon-checked			: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>");
//$custom-radio-indicator-icon-checked			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z' fill='%23005666'/%3E%3C/svg%3E");
$custom-radio-indicator-icon-checked			: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005666'/%3E%3C/svg%3E");

// select
$form-select-padding-y:             $input-padding-y;
$form-select-padding-x:             $input-padding-x;
$form-select-font-family:           $input-font-family;
$form-select-font-size:             $input-font-size;
$form-select-indicator-padding:     $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight;
$form-select-line-height:           $input-line-height;
$form-select-color:                 $input-color;
$form-select-bg:                    $input-bg;
$form-select-disabled-color:        null;
$form-select-disabled-bg:           $input-disabled-bg;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position:           right $form-select-padding-x center;
$form-select-bg-size:               16px 12px; // In pixels because image dimensions
$form-select-indicator-color:       $gray-darker;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-width='1.5' d='M1 1L7 7L13 1'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half;

$form-select-border-width:        $input-border-width;
$form-select-border-color:        $input-border-color;
$form-select-border-radius:       $input-border-radius;
$form-select-box-shadow:          $box-shadow-inset;

$form-select-focus-border-color:  $input-focus-border-color;
$form-select-focus-width:         $input-focus-width;
$form-select-focus-box-shadow:    0 0 0 $form-select-focus-width $input-btn-focus-color;

$form-select-padding-y-sm:        $input-padding-y-sm;
$form-select-padding-x-sm:        $input-padding-x-sm;
$form-select-font-size-sm:        $input-font-size-sm;

$form-select-padding-y-lg:        $input-padding-y-lg;
$form-select-padding-x-lg:        $input-padding-x-lg;
$form-select-font-size-lg:        $input-font-size-lg;

$form-select-transition:          $input-transition;


// z-index.
$zindex-backdrop	: 1000;
$zindex-search		: 1001;