// Header menu styles.


.navbar-nav {
  position: relative;
  width: 100%;
  .navbar-signup {
    padding: 15px 16px;
    border-bottom: 1px solid $gray;
  }
  @include media-breakpoint-up(md) {
    .navbar-signup {
      display: none;
    }
  }
  @include media-breakpoint-up($nav-bp) {

  }
  @include media-breakpoint-down($nav-bp) {
    position: fixed;
    left: 100%;
    z-index: 2;
    top: 83px;
    bottom: 0;
    //height: calc(100vh - 83px); // with calculated height, but without top/bottom, you don't see last li item on mobile
    background-color: $white;
    //border-top: 1px solid $gray;
    visibility: hidden;
    transition: left $tr-anim, visibility $tr-anim;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .menu-opened & {
      left: 0;
      visibility: visible;
    }
  }
}


.navbar-menu {
  @include list-unstyled;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  a {
    font-weight: 700;
    color: $primary-dark;
    text-decoration: none;
  }
  .has-icon {
    > a {
      position: relative;
      .link-icon {
        display: none;
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
  }
  .title-link {
    .link-icon {
      display: none;
    }
  }
  .link-icon {
    width: 32px;
    margin-right: 16px;
  }
  .link-desc {
    font-size: 14px;
    font-weight: 400;
    display: none;
    color: $body-color;
    margin-top: 2px;
  }
  .sub-menu.level-1,
  .sub-menu.level-2 {
    .link-desc {
      display: block;
    }
  }
  .title-link-wrapper { // copied link
    font-size: 14px;
    font-weight: 400;
    color: $body-color;
    .title-link {
      font-size: 28px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
    .link-desc {
      font-size: 17px;
      margin-bottom: 16px;
    }
  }
  .decor-not-clickable {
    > .menu-link {
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      pointer-events: none;
      &:focus-visible {
        box-shadow: unset;
      }
    }
  }
  > .menu-item { // depth 0
    border-bottom: 1px solid $gray;

    > a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      line-height: 1.3125;
      padding: 24px 32px;
      text-decoration: none;
      transition: color $tr-anim-xs, background-color $tr-anim-xs;
      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 3; // more than sub-menu-block
        height: 4px;
        width: 100%;
        margin-bottom: -1px;
        content: '';
        background-color: transparent;
        transition: background-color $tr-anim-xs;
      }
      .link-text {
        margin-right: auto; // when there is .link-icon to move text to the left
      }
      &:hover {
        color: $white;
        background-color: $dark-purple;
        &::before {
          background-color: $primary;
        }
        &::after {
          border-color: $white;
        }
      }
    }
  }
  .menu-item-has-children {
    &.three-custom-blocks { // custom block with 3 blocks
      > .sub-menu-block {
        > .container {
          > .sub-menu-block-inner {
            > .sub-menu {
              > li {
                padding: 4px 12px;
                border-radius: $radius-sm;
                .sub-menu-block {
                  li {
                    margin-bottom: 14px;
                  }
                }
                &:nth-child(1) {
                  background-color: rgba(#BFB8D8, .2);
                  border: 1px solid rgba(#D9D9D9, .46);
                }
                &:nth-child(2) {
                  background-color: transparent;
                  border: 1px solid rgba(#9747FF, .52);
                }
                &:nth-child(3) {
                  background-color: $white;
                  border: 1px solid rgba(#EA9FCB, .14);
                }
              }
            }
          }
        }
      }
    }
  }
  > .menu-item-has-children { // depth 0 with submenu
    > .sub-menu-block {
      display: none;
      padding-top: 36px;
      padding-bottom: 20px;
      > .container {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
        .container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    > a {
      &::after {
        display: block;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        content: '';
        border: 2px solid $gray3;
        border-width: 0 0 2px 2px;
        margin-top: -4px;
        margin-left: 12px;
        margin-right: 3px;
        transform: rotate(-45deg);
        transition: border-color $tr-anim-xs;
      }
    }
    &.active { // li opened
      border-bottom: 4px solid $tertiary;
      > a {
        color: $white;
        background-color: $dark-purple;
        &::before {
          background-color: $primary;
        }
        &::after {
          border-color: $white;
          transform: rotate(135deg);
          margin-top: 0;
        }
      }
    }
  }
  .sub-menu-block {
    .menu-item {
      margin-bottom: 22px;
    }
    a {
      .link-text {
        position: relative;
        display: inline-flex;
        &::before {
          position: absolute;
          top: 100%;
          display: block;
          width: 0;
          height: 2px;
          background-color: $primary;
          content: '';
          opacity: 0;
          transition: width $tr-anim, opacity $tr-anim;
        }
      }
      &:hover {
        .link-text {
          &::before {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
    .title-link-wrapper {
      a {
        .link-text {
          &::before {
            height: 4px;
          }
        }
      }
    }
  }
  .sub-menu {
    @include list-unstyled;
    .menu-item-has-children {
      >.sub-menu-block {
        margin-top: 12px;
      }
    }
    &.level-0 {
      > .menu-item {
        > .menu-link {
          font-size: 20px; // after changing from Inter font
          line-height: 1.2;
        }
      }
      >.menu-item-has-children {
        >.sub-menu-block {
          margin-top: 20px;
        }
      }
    }
  }
  .level-0 {
    margin-top: 8px;
    > .has-icon > a .link-icon {
      top: -4px;
    }
    > .menu-item {
      > .menu-link {
        .link-text {
          &:after {
            display: inline-block;
            flex-shrink: 0;
            width: 22px;
            height: 22px;
            margin-top: 0px;
            margin-left: 18px;
            transform: translateX(-12px);
            content: '';
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E") center/contain no-repeat;
            transition: transform $tr-anim;
          }
        }

        &:hover {
          .link-text {
            &::after {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down($nav-bp) {
    > .menu-item-has-children { // depth 0 with submenu
      > .sub-menu-block {
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          left: 32px;
          width: 28px;
          height: 40px;
          content: '';
          border-width: 20px 14px 0 14px;
          border-style: solid;
          border-color: $primary transparent transparent transparent;
        }
      }
    }
  }
  @include media-breakpoint-down($nav-cols-bp) {
    .sub-menu {
      &.level-0 {
        > .menu-item-has-children {
          + li {
            margin-top: 48px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up($nav-cols-bp) {
    .link-desc {
      display: block;
    }
    .has-icon {
      > a {
        padding-left: 48px;
        .link-icon {
          display: block;
        }
      }
    }
    .title-link-wrapper {
      .link-text {
        &:after {
          display: inline-block;
          flex-shrink: 0;
          width: 1.125em;
          height: 1.125em;
          margin-top: 2px;
          margin-left: 12px;
          content: '';
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E")
          center/contain no-repeat;
        }
      }
    }
    .title-link {
      display: inline-flex; // to keep .link-icon in one row if title is too big
      .link-icon {
        display: block;
      }
    }
    > .menu-item {
      &.has-icon {
        > a {
          padding-left: 80px;
          .link-icon {
            display: block;
            top: 20px;
            left: 32px;
          }
        }
      }
    }
    .menu-item-has-children { // depth 0 with submenu
      &.col-2,
      &.col-3 {
        > .sub-menu-block {
          > .container {
            > .sub-menu-block-inner {
              >.sub-menu {
                @include row;
                > li {
                  padding-left: calc($grid-gutter-width / 2);
                  padding-right: calc($grid-gutter-width / 2);
                  &.three-custom-blocks {
                    padding-right: 0;
                  }
                  //display: contents;
                  > a {
                  }
                }
                &.level-0 {
                  > .menu-item-has-children {
                    display: flex;
                    flex-direction: column;
                    > .sub-menu-block {
                      position: relative;
                      flex-grow: 1;
                      padding-left: 48px;
                      &::before {
                        position: absolute;
                        top: 0;
                        left: 16px;
                        content: '';
                        width: 1px;
                        height: 100%;
                        background-color: $gray-light;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.col-2 {
        > .sub-menu-block {
          > .container {
            > .sub-menu-block-inner {
              > .sub-menu {
                > li {
                  width: 50%;
                }
              }
            }
          }
        }
      }
      &.col-3 {
        > .sub-menu-block {
          > .container {
            > .sub-menu-block-inner {
              > .sub-menu {
                > li {
                  width: 33.33334%;
                }
              }
            }
          }
        }
      }
      &.three-custom-blocks { // custom block with 3 blocks
        > .sub-menu-block {
          > .container {
            > .sub-menu-block-inner {
              > .sub-menu {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                > li {
                  margin-bottom: 0;
                  &:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 2;
                  }
                  &:nth-child(2) {
                    grid-area: 1 / 2 / 3 / 3;
                  }
                  &:nth-child(3) {
                    grid-area: 2 / 1 / 3 / 2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .level-0 {
      margin-top: 36px;
    }
  }
  @include media-breakpoint-up($nav-bp) {
    flex-direction: row;
    .title-link-wrapper {
      .title-link {
        font-size: 32px;
        margin-bottom: 24px;
      }
      .link-desc {
        width: 50%;
        padding-right: calc($grid-gutter-width/2);
      }
    }
    > .menu-item { // depth 0
      flex-grow: 1; // to have hover, that don't disappear from item to item
      border: none;
      text-align: center;
      > .sub-menu-block {
        text-align: left;
      }
      &.has-icon {
        > a {
          padding-left: 58px;
          .link-icon {
            top: 15px;
            left: 10px;
          }
        }
      }
      > a {
        font-size: 15px;
        display: inline-flex;
        justify-content: flex-start;
        text-align: left;
        padding: 20px 10px;
        height: 100%;
        &::before {
          transition: background-color $tr-anim;
        }
        &:hover {
          color: $primary-dark;
          background-color: transparent;
          &::before {
            background-color: $primary-dark;
          }
        }
      }
    }
    > .menu-item-has-children { // depth 0 with submenu
      > .sub-menu-block {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        width: 100%;
        display: block;
        padding-top: 64px;
        padding-bottom: 64px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        background-color: $gray-lightest;
        transition: opacity $tr-anim, visibility $tr-anim;
        transition-delay: 300ms;
        &::before {
          position: absolute;
          background-color: inherit;
          left: -9999px;
          right: -9999px;
          top: 0;
          height: 100%;
          content: '';
          z-index: -1;
          box-shadow: $box-shadow-alt;
        }
        > .container {
          padding-left: 0;
          padding-right: 0;
        }
      }

      // For touch devices, toggle visibility on click
      body.touch-device &.active {
        border-bottom: unset;
        > a {
          color: $primary-dark;
          background-color: transparent;
        }
        > .sub-menu-block {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
      // For touch devices, toggle visibility on click
      body.touch-device & > .sub-menu-block {
        display: none;
        margin-left: -1000px; // to avoid overflow hidden on slideToggle
        margin-right: -1000px;
        padding-left: 1000px;
        padding-right: 1000px;
        width: auto;
      }

      > a {
        &::after {
          border-color: $gray-darker;
        }
      }
      &:hover,
      &:focus-within {
        > a {
          color: $primary-dark;
          background-color: transparent;
          &::before {
            background-color: $primary-dark;
          }
          &::after {
            margin-top: 4px;
            transform: rotate(135deg);
            border-color: $gray-darker;
          }
        }
        > .sub-menu-block {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
    }
    .sub-menu-block {
      .menu-item {
        margin-bottom: 20px;
      }
    }
    .sub-menu.level-0 {
      > .menu-item-has-children {
        > .sub-menu-block {
          margin-top: 36px;
        }
      }
    }
    .level-1 {
      > .menu-item-has-children {
        > .sub-menu-block {
          margin-top: 16px;
        }
      }
    }
  }
}
