h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $ff-title;
	font-weight: $fw-bold;
	line-height: $lh-title;
	margin: 0 0 1.5rem;
	color: $primary-dark;
}

h1, .h1 {
	font-size: 36px;

	@include media-breakpoint-up(md) {
		font-size: 64px;
	}
}

h2, .h2 {
	font-size: 32px;

	@include media-breakpoint-up(md) {
		font-size: 48px;
	}
}

h3, .h3 {
	font-size: 28px;
	@include media-breakpoint-up(md) {
		font-size: 32px;
	}
}

h4, .h4 {
	font-size: 24px;

	@include media-breakpoint-up(md) {
		font-size: 28px;
	}
}

h5, .h5 {
	font-size: $fs-xl;
}

h6, .h6 {
	font-size: $fs-lg;
}

a {
	display: inline-block;
	text-decoration: underline;
	transition: color $tr-anim;
	&:hover {
		text-decoration: none;
	}

	&:focus-visible {
		//outline: 2px solid $gray-lightest;
		//outline-offset: 2px;
		border-radius: $radius-xs;
		box-shadow: 0 0 0 2px $gray-lightest, 0 0 0 4px $primary-dark;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1.5rem;

	&:last-of-type {
		//margin-bottom: 0;
	}
}

b, strong {
	font-weight: $fw-bold;
}

section {
	.section-title {
		letter-spacing: -.5px;
		line-height: 1.2;
	}
}

@include media-breakpoint-up(md) {
	.home {
		section {
			.section-title {
				font-size: 40px;
			}
		}
	}
}