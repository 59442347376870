:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.card-team {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  margin: 1.5rem 0;
}
.card-team .img-wrapper {
  width: 120px;
  height: 120px;
  background-color: #fff;
  border: 1px solid #BFB8D8;
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px rgba(167, 141, 252, 0.2);
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.card-team .card-img {
  position: relative;
  padding-top: 20px;
  margin-bottom: -60px;
}
.card-team .card-img + .card-body {
  padding-top: 72px;
}
.card-team .card-body {
  flex-grow: 1;
  padding: 32px 32px 32px;
  background-color: #fff;
  border: 1px solid #BFB8D8;
  border-radius: 24px;
}
.card-team .card-text + .address {
  margin-top: 1rem;
  margin-bottom: 0;
  padding-top: 1rem;
  border-top: 1px solid #BFB8D8;
}
.card-team .name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
}
.card-team .address .address-item {
  margin-bottom: 0.75rem;
}
.card-team .address .address-item:last-child {
  margin-bottom: 0;
}
.card-team .address .address-item a {
  display: flex;
  align-items: center;
}
.card-team .address .item-icon {
  width: 100%;
  max-width: 32px;
  margin-right: 1rem;
  filter: brightness(0.1);
}
@supports (-webkit-appearance:none){

}
