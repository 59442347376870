:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 1.5rem;
  color: #005666;
}

h1, .h1 {
  font-size: 36px;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 64px;
  }
}

h2, .h2 {
  font-size: 32px;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 48px;
  }
}

h3, .h3 {
  font-size: 28px;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 32px;
  }
}

h4, .h4 {
  font-size: 24px;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 28px;
  }
}

h5, .h5 {
  font-size: 20px;
}

h6, .h6 {
  font-size: 18px;
}

a {
  display: inline-block;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}
a:hover {
  text-decoration: none;
}
a:focus-visible {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
b, strong {
  font-weight: 700;
}

section .section-title {
  letter-spacing: -0.5px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .home section .section-title {
    font-size: 40px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.col {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1 1 0;
}

* {
  outline: none;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

body {
  position: relative;
  min-width: 300px;
  display: flex;
  height: auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #1D1D1D;
  background-color: #F2F2F2;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.wrapper .footer {
  margin-top: auto;
}

.container {
  width: 100%;
  max-width: 1504px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding: 40px 0;
}
@media (min-width: 768px) {
  .section {
    padding: 64px 0;
  }
}

@media (min-width: 768px) {
  .section-6-col .section-inner {
    max-width: 724px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .section-8-col .section-inner {
    max-width: 972px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .section-10-col .section-inner {
    max-width: 1216px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* STYLES FOR WP CONTENT */
.page-content {
  clear: both;
}
.page-content::after {
  display: block;
  content: "";
  clear: both;
}

.text-center {
  text-align: center;
}

.aligncenter {
  text-align: center;
}

.alignleft {
  text-align: left;
}

.alignright {
  text-align: right;
}

img.alignleft, .wp-caption.alignleft {
  float: left;
  margin-right: 15px;
}

img.alignright, .wp-caption.alignright {
  float: right;
  margin-left: 15px;
}

.wp-caption {
  max-width: 100%;
}
.wp-caption.aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.wp-caption-text {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  margin-top: 4px;
}

img.alignleft, .wp-caption.alignleft {
  float: none;
  margin-right: 0;
}

img.alignright, .wp-caption.alignright {
  float: none;
  margin-left: 0;
}

/* END STYLES FOR WP CONTENT */
.wp-block-image figcaption,
.wp-block-table figcaption,
.wp-block-embed figcaption {
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
}

.wp-block-table figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.col-img .img-holder img {
  width: auto;
}

.header {
  min-height: 82px;
  background-color: #fff;
  border-bottom: 1px solid #909090;
}
@media (min-width: 1200px) {
  .header {
    padding-top: 37px;
    border-color: #F2F2F2;
  }
}

.navbar-top {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
.navbar-top .navbar-signup,
.navbar-top .navbar-search,
.navbar-top .navbar-toggler {
  margin-left: auto;
}
.navbar-top .navbar-signup + .navbar-search {
  margin-left: 48px;
}
.navbar-top .navbar-search + .navbar-toggler {
  margin-left: 16px;
}
.navbar-top .navbar-brand {
  height: 50px;
  margin-right: auto;
}
.navbar-top .navbar-brand img {
  height: 100%;
  width: auto;
}
@media (max-width: 767.98px) {
  .navbar-top .navbar-signup {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-top {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-top .search-toggler {
    width: 64px;
    height: 64px;
  }
  .navbar-top .navbar-brand {
    height: 95px;
    margin-left: 44px;
  }
}

.navbar-brand img {
  transition: filter 0.3s ease-in-out;
}
.navbar-brand:hover svg path:first-of-type {
  fill: #B32974;
}
.navbar-brand:hover img {
  filter: saturate(0.5) contrast(1.3);
}

.search-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #1D1D1D;
  background-color: #00CCCC;
  border: 1px solid #fff;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.search-toggler svg {
  width: 50%;
}
.search-toggler:hover {
  color: #fff;
  background-color: #1D1D1D;
}
.search-toggler:focus-visible {
  border-radius: 50%;
}

.search-form-block {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 13px 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 1002;
}
.search-form-block.is-shown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modal-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}

.search-form-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 822px;
  margin-left: auto;
  gap: 12px;
  padding: 3px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid #453966;
  border-radius: 3rem;
}
.search-form-wrapper .search-form-text {
  font-size: 12px;
  padding-left: 0.5rem;
}
.search-form-wrapper .search-form-text p {
  margin-bottom: 0;
}
.search-form-wrapper .search-form-holder {
  position: relative;
  flex-grow: 1;
}
.search-form-wrapper .search-form-holder .btn-search {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 4px;
}
.search-form-wrapper .form-group-search .form-control:focus {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: #B4AD00;
}
.search-form-wrapper .btn-search-close {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  color: #fff;
  background-color: #005666;
  border: 1px solid #005666;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}
.search-form-wrapper .btn-search-close::before, .search-form-wrapper .btn-search-close::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  width: 32px;
  height: 3px;
  background-color: #fff;
  margin-top: -1px;
  margin-left: -16px;
  transform: rotate(45deg);
}
.search-form-wrapper .btn-search-close::after {
  transform: rotate(-45deg);
}
.search-form-wrapper .btn-search-close:hover {
  color: #005666;
  background-color: #fff;
}
.search-form-wrapper .btn-search-close:hover::before, .search-form-wrapper .btn-search-close:hover::after {
  background-color: #1D1D1D;
}
@media (min-width: 1200px) {
  .search-form-wrapper {
    border: 2px solid #BEFAB9;
    padding: 6px;
    gap: 16px;
    margin-top: 19px;
    margin-right: -4px;
  }
  .search-form-wrapper .search-form-text {
    font-size: 22px;
    font-weight: 700;
    padding-left: 1rem;
  }
  .search-form-wrapper .btn-search {
    width: 48px;
    height: 48px;
  }
  .search-form-wrapper .btn-search svg {
    width: 24px;
  }
  .search-form-wrapper .search-form-holder .btn-search {
    right: 8px;
    margin-top: -24px;
  }
  .search-form-wrapper .form-group-search .form-control {
    padding: 19px 64px 19px 24px;
  }
  .search-form-wrapper .btn-search-close {
    width: 64px;
    height: 64px;
    color: #1D1D1D;
    background-color: #fff;
  }
  .search-form-wrapper .btn-search-close::before, .search-form-wrapper .btn-search-close::after {
    width: 40px;
    margin-left: -20px;
    background-color: #1D1D1D;
  }
  .search-form-wrapper .btn-search-close:hover {
    color: #fff;
    background-color: #005666;
  }
  .search-form-wrapper .btn-search-close:hover::before, .search-form-wrapper .btn-search-close:hover::after {
    background-color: #fff;
  }
}

.form-group-search input[type=submit] {
  display: none;
}
.form-group-search .form-control {
  background-color: #fff;
  border-color: #D2F4DC;
  padding: 11px 16px;
  border-radius: 3rem;
}

.navbar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 8px;
  color: #1D1D1D;
  background-color: transparent;
  border: 1px solid #110E19;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
}
.navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 24px;
  height: 3px;
  background-color: #110E19;
}
.navbar-toggler .navbar-toggler-icon::before, .navbar-toggler .navbar-toggler-icon::after {
  position: absolute;
  width: 100%;
  height: 3px;
  top: -9px;
  left: 0;
  content: "";
  background-color: inherit;
  transition: transform 0.3s ease-in-out;
}
.navbar-toggler .navbar-toggler-icon::after {
  top: 9px;
}
.navbar-toggler:hover {
  background-color: #005666;
  border-color: #005666;
}
.navbar-toggler:hover .navbar-toggler-icon {
  background-color: #fff;
}
.navbar-toggler:focus-visible {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.menu-opened .navbar-toggler {
  background-color: #005666;
  border-color: #005666;
}
.menu-opened .navbar-toggler .navbar-toggler-icon {
  background-color: transparent;
}
.menu-opened .navbar-toggler .navbar-toggler-icon::before, .menu-opened .navbar-toggler .navbar-toggler-icon::after {
  width: 34px;
  background-color: #fff;
}
.menu-opened .navbar-toggler .navbar-toggler-icon::before {
  transform: rotate(45deg) translate(3px, 10px);
}
.menu-opened .navbar-toggler .navbar-toggler-icon::after {
  transform: rotate(-45deg) translate(3px, -10px);
}

.navbar-nav {
  position: relative;
  width: 100%;
}
.navbar-nav .navbar-signup {
  padding: 15px 16px;
  border-bottom: 1px solid #909090;
}
@media (min-width: 768px) {
  .navbar-nav .navbar-signup {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-nav {
    position: fixed;
    left: 100%;
    z-index: 2;
    top: 83px;
    bottom: 0;
    background-color: #fff;
    visibility: hidden;
    transition: left 0.3s ease-in-out, visibility 0.3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .menu-opened .navbar-nav {
    left: 0;
    visibility: visible;
  }
}

.navbar-menu {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.navbar-menu a {
  font-weight: 700;
  color: #005666;
  text-decoration: none;
}
.navbar-menu .has-icon > a {
  position: relative;
}
.navbar-menu .has-icon > a .link-icon {
  display: none;
  position: absolute;
  top: -2px;
  left: 0;
}
.navbar-menu .title-link .link-icon {
  display: none;
}
.navbar-menu .link-icon {
  width: 32px;
  margin-right: 16px;
}
.navbar-menu .link-desc {
  font-size: 14px;
  font-weight: 400;
  display: none;
  color: #1D1D1D;
  margin-top: 2px;
}
.navbar-menu .sub-menu.level-1 .link-desc,
.navbar-menu .sub-menu.level-2 .link-desc {
  display: block;
}
.navbar-menu .title-link-wrapper {
  font-size: 14px;
  font-weight: 400;
  color: #1D1D1D;
}
.navbar-menu .title-link-wrapper .title-link {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 20px;
}
.navbar-menu .title-link-wrapper .link-desc {
  font-size: 17px;
  margin-bottom: 16px;
}
.navbar-menu .decor-not-clickable > .menu-link {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  pointer-events: none;
}
.navbar-menu .decor-not-clickable > .menu-link:focus-visible {
  box-shadow: unset;
}
.navbar-menu > .menu-item {
  border-bottom: 1px solid #909090;
}
.navbar-menu > .menu-item > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  line-height: 1.3125;
  padding: 24px 32px;
  text-decoration: none;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}
.navbar-menu > .menu-item > a::before {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 4px;
  width: 100%;
  margin-bottom: -1px;
  content: "";
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
.navbar-menu > .menu-item > a .link-text {
  margin-right: auto;
}
.navbar-menu > .menu-item > a:hover {
  color: #fff;
  background-color: #110E19;
}
.navbar-menu > .menu-item > a:hover::before {
  background-color: #00CCCC;
}
.navbar-menu > .menu-item > a:hover::after {
  border-color: #fff;
}
.navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li {
  padding: 4px 12px;
  border-radius: 8px;
}
.navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li .sub-menu-block li {
  margin-bottom: 14px;
}
.navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(1) {
  background-color: rgba(191, 184, 216, 0.2);
  border: 1px solid rgba(217, 217, 217, 0.46);
}
.navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(2) {
  background-color: transparent;
  border: 1px solid rgba(151, 71, 255, 0.52);
}
.navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(3) {
  background-color: #fff;
  border: 1px solid rgba(234, 159, 203, 0.14);
}
.navbar-menu > .menu-item-has-children > .sub-menu-block {
  display: none;
  padding-top: 36px;
  padding-bottom: 20px;
}
.navbar-menu > .menu-item-has-children > .sub-menu-block > .container {
  padding-left: 32px;
  padding-right: 32px;
}
.navbar-menu > .menu-item-has-children > .sub-menu-block > .container .container {
  padding-left: 0;
  padding-right: 0;
}
.navbar-menu > .menu-item-has-children > a::after {
  display: block;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  content: "";
  border: 2px solid #6F6F6F;
  border-width: 0 0 2px 2px;
  margin-top: -4px;
  margin-left: 12px;
  margin-right: 3px;
  transform: rotate(-45deg);
  transition: border-color 0.1s ease-in-out;
}
.navbar-menu > .menu-item-has-children.active {
  border-bottom: 4px solid #E1BC1E;
}
.navbar-menu > .menu-item-has-children.active > a {
  color: #fff;
  background-color: #110E19;
}
.navbar-menu > .menu-item-has-children.active > a::before {
  background-color: #00CCCC;
}
.navbar-menu > .menu-item-has-children.active > a::after {
  border-color: #fff;
  transform: rotate(135deg);
  margin-top: 0;
}
.navbar-menu .sub-menu-block .menu-item {
  margin-bottom: 22px;
}
.navbar-menu .sub-menu-block a .link-text {
  position: relative;
  display: inline-flex;
}
.navbar-menu .sub-menu-block a .link-text::before {
  position: absolute;
  top: 100%;
  display: block;
  width: 0;
  height: 2px;
  background-color: #00CCCC;
  content: "";
  opacity: 0;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.navbar-menu .sub-menu-block a:hover .link-text::before {
  width: 100%;
  opacity: 1;
}
.navbar-menu .sub-menu-block .title-link-wrapper a .link-text::before {
  height: 4px;
}
.navbar-menu .sub-menu {
  padding-left: 0;
  list-style: none;
}
.navbar-menu .sub-menu .menu-item-has-children > .sub-menu-block {
  margin-top: 12px;
}
.navbar-menu .sub-menu.level-0 > .menu-item > .menu-link {
  font-size: 20px;
  line-height: 1.2;
}
.navbar-menu .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block {
  margin-top: 20px;
}
.navbar-menu .level-0 {
  margin-top: 8px;
}
.navbar-menu .level-0 > .has-icon > a .link-icon {
  top: -4px;
}
.navbar-menu .level-0 > .menu-item > .menu-link .link-text:after {
  display: inline-block;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-top: 0px;
  margin-left: 18px;
  transform: translateX(-12px);
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E") center/contain no-repeat;
  transition: transform 0.3s ease-in-out;
}
.navbar-menu .level-0 > .menu-item > .menu-link:hover .link-text::after {
  transform: translateX(0);
}
@media (max-width: 1199.98px) {
  .navbar-menu > .menu-item-has-children > .sub-menu-block {
    position: relative;
  }
  .navbar-menu > .menu-item-has-children > .sub-menu-block::before {
    position: absolute;
    top: 0;
    left: 32px;
    width: 28px;
    height: 40px;
    content: "";
    border-width: 20px 14px 0 14px;
    border-style: solid;
    border-color: #00CCCC transparent transparent transparent;
  }
}
@media (max-width: 991.98px) {
  .navbar-menu .sub-menu.level-0 > .menu-item-has-children + li {
    margin-top: 48px;
  }
}
@media (min-width: 992px) {
  .navbar-menu .link-desc {
    display: block;
  }
  .navbar-menu .has-icon > a {
    padding-left: 48px;
  }
  .navbar-menu .has-icon > a .link-icon {
    display: block;
  }
  .navbar-menu .title-link-wrapper .link-text:after {
    display: inline-block;
    flex-shrink: 0;
    width: 1.125em;
    height: 1.125em;
    margin-top: 2px;
    margin-left: 12px;
    content: "";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E") center/contain no-repeat;
  }
  .navbar-menu .title-link {
    display: inline-flex;
  }
  .navbar-menu .title-link .link-icon {
    display: block;
  }
  .navbar-menu > .menu-item.has-icon > a {
    padding-left: 80px;
  }
  .navbar-menu > .menu-item.has-icon > a .link-icon {
    display: block;
    top: 20px;
    left: 32px;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li {
    padding-left: 16px;
    padding-right: 16px;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li.three-custom-blocks, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li.three-custom-blocks {
    padding-right: 0;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children {
    display: flex;
    flex-direction: column;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block {
    position: relative;
    flex-grow: 1;
    padding-left: 48px;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block::before, .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block::before {
    position: absolute;
    top: 0;
    left: 16px;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #D1D1D1;
  }
  .navbar-menu .menu-item-has-children.col-2 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li {
    width: 50%;
  }
  .navbar-menu .menu-item-has-children.col-3 > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li {
    width: 33.33334%;
  }
  .navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li {
    margin-bottom: 0;
  }
  .navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  .navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(2) {
    grid-area: 1/2/3/3;
  }
  .navbar-menu .menu-item-has-children.three-custom-blocks > .sub-menu-block > .container > .sub-menu-block-inner > .sub-menu > li:nth-child(3) {
    grid-area: 2/1/3/2;
  }
  .navbar-menu .level-0 {
    margin-top: 36px;
  }
}
@media (min-width: 1200px) {
  .navbar-menu {
    flex-direction: row;
  }
  .navbar-menu .title-link-wrapper .title-link {
    font-size: 32px;
    margin-bottom: 24px;
  }
  .navbar-menu .title-link-wrapper .link-desc {
    width: 50%;
    padding-right: 16px;
  }
  .navbar-menu > .menu-item {
    flex-grow: 1;
    border: none;
    text-align: center;
  }
  .navbar-menu > .menu-item > .sub-menu-block {
    text-align: left;
  }
  .navbar-menu > .menu-item.has-icon > a {
    padding-left: 58px;
  }
  .navbar-menu > .menu-item.has-icon > a .link-icon {
    top: 15px;
    left: 10px;
  }
  .navbar-menu > .menu-item > a {
    font-size: 15px;
    display: inline-flex;
    justify-content: flex-start;
    text-align: left;
    padding: 20px 10px;
    height: 100%;
  }
  .navbar-menu > .menu-item > a::before {
    transition: background-color 0.3s ease-in-out;
  }
  .navbar-menu > .menu-item > a:hover {
    color: #005666;
    background-color: transparent;
  }
  .navbar-menu > .menu-item > a:hover::before {
    background-color: #005666;
  }
  .navbar-menu > .menu-item-has-children > .sub-menu-block {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    width: 100%;
    display: block;
    padding-top: 64px;
    padding-bottom: 64px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: #F2F2F2;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition-delay: 300ms;
  }
  .navbar-menu > .menu-item-has-children > .sub-menu-block::before {
    position: absolute;
    background-color: inherit;
    left: -9999px;
    right: -9999px;
    top: 0;
    height: 100%;
    content: "";
    z-index: -1;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 90, 0.1);
  }
  .navbar-menu > .menu-item-has-children > .sub-menu-block > .container {
    padding-left: 0;
    padding-right: 0;
  }
  body.touch-device .navbar-menu > .menu-item-has-children.active {
    border-bottom: unset;
  }
  body.touch-device .navbar-menu > .menu-item-has-children.active > a {
    color: #005666;
    background-color: transparent;
  }
  body.touch-device .navbar-menu > .menu-item-has-children.active > .sub-menu-block {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  body.touch-device .navbar-menu > .menu-item-has-children > .sub-menu-block {
    display: none;
    margin-left: -1000px;
    margin-right: -1000px;
    padding-left: 1000px;
    padding-right: 1000px;
    width: auto;
  }
  .navbar-menu > .menu-item-has-children > a::after {
    border-color: #1D1D1D;
  }
  .navbar-menu > .menu-item-has-children:hover > a, .navbar-menu > .menu-item-has-children:focus-within > a {
    color: #005666;
    background-color: transparent;
  }
  .navbar-menu > .menu-item-has-children:hover > a::before, .navbar-menu > .menu-item-has-children:focus-within > a::before {
    background-color: #005666;
  }
  .navbar-menu > .menu-item-has-children:hover > a::after, .navbar-menu > .menu-item-has-children:focus-within > a::after {
    margin-top: 4px;
    transform: rotate(135deg);
    border-color: #1D1D1D;
  }
  .navbar-menu > .menu-item-has-children:hover > .sub-menu-block, .navbar-menu > .menu-item-has-children:focus-within > .sub-menu-block {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .navbar-menu .sub-menu-block .menu-item {
    margin-bottom: 20px;
  }
  .navbar-menu .sub-menu.level-0 > .menu-item-has-children > .sub-menu-block {
    margin-top: 36px;
  }
  .navbar-menu .level-1 > .menu-item-has-children > .sub-menu-block {
    margin-top: 16px;
  }
}

.footer {
  position: relative;
  color: #fff;
  background-color: #013641;
}

.footer-navs {
  padding-top: 32px;
  padding-bottom: 32px;
}
.footer-navs .footer-nav-title {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  line-height: 1.2;
  color: inherit;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .footer-navs {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.footer-navs-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 34px 32px;
}
.footer-navs-inner .footer-col {
  width: 100%;
}
.footer-navs-inner nav + nav {
  margin-top: 66px;
}
@media (min-width: 576px) {
  .footer-navs-inner .footer-col {
    width: calc(50% - 16px);
  }
}
@media (min-width: 992px) {
  .footer-navs-inner .footer-col {
    flex: 1 0 0;
    width: 100%;
    max-width: 100%;
  }
}

.footer-nav {
  padding-left: 0;
  list-style: none;
}
.footer-nav a {
  text-decoration: none;
}
.footer-nav a::after {
  display: block;
  width: 0;
  height: 1px;
  border-bottom: 1px solid;
  content: "";
  opacity: 0;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.footer-nav a:hover {
  text-decoration: none;
}
.footer-nav a:hover::after {
  width: 100%;
  opacity: 1;
}
.footer-nav ul {
  padding-left: 0;
  list-style: none;
}
.footer-nav li {
  margin-bottom: 0.5rem;
}
.footer-nav li + .menu-item-has-children {
  margin-top: 54px;
}
.footer-nav a:focus-visible {
  box-shadow: 0 0 0 2px #005666, 0 0 0 4px #F2F2F2;
}
.footer-nav .menu-item-has-children > a {
  font-weight: 500;
  color: #fff;
  margin-bottom: 1rem;
}
.footer-nav a {
  color: #ACAAB2;
}
.footer-nav a:hover {
  color: #fff;
}

.footer-info {
  background-color: #005666;
  padding-top: 32px;
  padding-bottom: 32px;
}
.footer-info .footer-info-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 18px 32px;
}
.footer-info .footer-col {
  width: 100%;
}
.footer-info .brand-block {
  font-size: 20px;
  line-height: 1.5;
  color: #EAEAEA;
}
.footer-info .navbar-brand {
  width: 148px;
  margin-bottom: 24px;
}
.footer-info .navbar-brand img {
  width: 100%;
}
@media (min-width: 576px) {
  .footer-info .footer-info-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
  .footer-info .footer-col {
    width: calc(50% - 16px);
  }
  .footer-info .footer-col.col-brand {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .footer-info {
    padding-top: 64px;
    padding-bottom: 32px;
  }
  .footer-info .navbar-brand {
    margin-bottom: 32px;
  }
}
@media (min-width: 992px) {
  .footer-info .footer-col {
    width: calc(25% - 24px);
  }
  .footer-info .footer-col.col-brand {
    width: calc(50% - 16px);
  }
}

.contact-block,
.follow-block {
  color: #EAEAEA;
}
.contact-block .footer-title,
.follow-block .footer-title {
  font-size: 32px;
  color: #fff;
  padding-bottom: 16px;
  border-bottom: 1px solid;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .contact-block .footer-title,
  .follow-block .footer-title {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
}

.contact-block .address .address-item {
  margin-bottom: 1rem;
}
.contact-block .address .address-item a {
  display: flex;
  align-items: center;
}
.contact-block .address .address-item a:hover .item-icon {
  filter: brightness(1.5);
}
.contact-block .address .item-address {
  font-size: 1rem;
}
.contact-block .address .item-icon {
  width: 100%;
  max-width: 40px;
  margin-right: 1rem;
  transition: opacity 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .contact-block .address {
    margin-bottom: 0;
  }
  .contact-block .address .address-item {
    margin-bottom: 2rem;
  }
  .contact-block .address .address-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 575.98px) {
  .follow-block {
    margin-top: 24px;
  }
  .follow-block .footer-title {
    padding-bottom: 8px;
  }
}

.footer-legal {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #012B33;
}
.footer-legal .footer-nav-legal,
.footer-legal p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer-legal-inner {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .footer-legal-inner .footer-nav-legal {
    margin-right: 48px;
  }
}

.footer-nav-legal {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 18px 24px;
}
.footer-nav-legal a {
  text-decoration: none;
}
.footer-nav-legal a::after {
  display: block;
  width: 0;
  height: 1px;
  border-bottom: 1px solid;
  content: "";
  opacity: 0;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.footer-nav-legal a:hover {
  text-decoration: none;
}
.footer-nav-legal a:hover::after {
  width: 100%;
  opacity: 1;
}
@media (max-width: 575.98px) {
  .footer-nav-legal li {
    width: calc(50% - 12px);
  }
}

.social-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.social-list li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 1rem;
}
.social-list a {
  transition: filter 0.3s ease-in-out;
}
.social-list a:hover {
  filter: brightness(1.5);
}
.social-list a:focus-visible {
  box-shadow: 0 0 0 2px #005666, 0 0 0 4px #F2F2F2;
}

.btn {
  display: inline-block;
  padding: 18px 22px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33334;
  color: #1D1D1D;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.btn:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.btn:active, .btn.active, .btn.show {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #1D1D1D;
  background-color: #00CCCC;
  border-color: #00CCCC;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}

.btn-primary-dark, .btn-signup {
  color: #fff;
  background-color: #005666;
  border-color: #005666;
}
.btn-primary-dark:hover, .btn-signup:hover {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}

.btn-secondary {
  color: #fff;
  background-color: #FF0099;
  border-color: #FF0099;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #993366;
  border-color: #993366;
}

.btn-light {
  color: #1D1D1D;
  background-color: #fff;
  border-color: #1D1D1D;
}
.btn-light:hover {
  color: #fff;
  background-color: #005666;
  border-color: #005666;
}

.btn-skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.btn-signup {
  font-size: 16px;
  line-height: 1.4375;
  letter-spacing: 0.16px;
  padding: 9px 32px;
  border-radius: 3rem;
}
.btn-signup:focus-visible {
  border-radius: 3rem;
}

.btn-search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.25em;
  color: #1D1D1D;
  background-color: #00CCCC;
  border-radius: 50%;
}
.btn-search svg {
  width: 1em;
}
.btn-search:hover {
  color: #fff;
  background-color: #1D1D1D;
}
.btn-search:focus-visible {
  border-radius: 50%;
}

.btn-scroll-top {
  position: absolute;
  right: 32px;
  margin-top: -26px;
  font-size: 1rem;
  font-weight: 700;
  padding: 14px;
  color: #fff;
  background-color: #005666;
  border: 1px solid #fff;
  border-radius: 8px;
}
.btn-scroll-top svg {
  margin-left: 12px;
  vertical-align: -0.35em;
}
.btn-scroll-top:hover {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #fff;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.9375rem 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1D1D1D;
  background-color: rgba(242, 242, 242, 0.4);
  background-clip: padding-box;
  border: 1px solid #F2F2F2;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  border-color: #005666;
  outline: 0;
  box-shadow: none;
}
.form-control:focus-visible {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #ACAAB2;
  opacity: 1;
}
.form-control:disabled {
  background-color: #D1D1D1;
  border-color: #F2F2F2;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.9375rem 1rem;
  margin: -0.9375rem -1rem;
  margin-inline-end: 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-label {
  margin-bottom: 1em;
  font-size: 1.25rem;
  font-weight: 600;
  color: #005666;
}

.form-group {
  margin-bottom: 1.5rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  color-adjust: exact;
  margin-bottom: 1.5rem;
}
.custom-control:hover .custom-control-input:not(:disabled) ~ .custom-control-label::before {
  box-shadow: 0 0 0 6px #00CCCC;
  background-color: #00CCCC;
}
.custom-control .custom-control-input:not(:disabled):focus-visible ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #005666;
  border-color: #005666;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #005666;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgba(0, 86, 102, 0.25);
  border-color: rgba(0, 86, 102, 0.25);
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #909090;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #D1D1D1;
}

.custom-control-label {
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0rem;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: transparent;
  border: #005666 solid 2px;
  transition: box-shadow 0.1s ease-in-out;
}
.custom-control-label::after {
  position: absolute;
  top: 0rem;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 8px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label {
  color: #005666;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") 50%/50% 50% no-repeat;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #005666;
  background-color: #fff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23005666' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(144, 144, 144, 0.5);
  border-color: rgba(0, 86, 102, 0.4);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(144, 144, 144, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label {
  color: #005666;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005666'/%3E%3C/svg%3E") 50%/50% 50% no-repeat;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(144, 144, 144, 0.5);
  border-color: rgba(0, 86, 102, 0.4);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.9375rem 3rem 0.9375rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1D1D1D;
  background-color: rgba(242, 242, 242, 0.4);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'%3e%3cpath fill='none' stroke='%231D1D1D' stroke-linecap='round' stroke-width='1.5' d='M1 1L7 7L13 1'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #005666;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 86, 102, 0.25);
}
.form-select:focus-visible {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #D1D1D1;
  border-color: #F2F2F2;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1D1D1D;
}

.form-select-sm {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 26px;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.breadcrumbs {
  font-size: 14px;
  padding: 6px 0 7px;
  background-color: #FBFBFB;
  border-bottom: 1px solid rgba(0, 86, 102, 0.1);
}
.breadcrumbs .breadcrumbs-sep {
  margin: 0 4px;
}
.breadcrumbs .current-item {
  font-weight: 700;
}
@media (min-width: 768px) {
  .breadcrumbs {
    padding: 1.375rem 0;
  }
  .breadcrumbs .breadcrumbs-sep {
    margin: 0 12px;
  }
}

@media (max-width: 767.98px) {
  .mobile-hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .desktop-hidden {
    display: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-16x9 {
  padding-top: 56.25%;
}

.js-collapse .text-hide {
  display: none;
}
.js-collapse.is-open .text-hide {
  display: block;
}
.js-collapse.is-open .text-show {
  display: none;
}
@supports (-webkit-appearance:none){
.navbar-menu .level-0 > .menu-item > .menu-link .link-text:after{background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E") center/contain no-repeat}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") 50%/50% 50% no-repeat}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23005666' d='M0 2h4'/%3e%3c/svg%3e")}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after{background:url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005666'/%3E%3C/svg%3E") 50%/50% 50% no-repeat}
.form-select{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'%3e%3cpath fill='none' stroke='%231D1D1D' stroke-linecap='round' stroke-width='1.5' d='M1 1L7 7L13 1'/%3e%3c/svg%3e")}
}

 @media (prefers-reduced-motion: no-preference){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 992px){

@supports (-webkit-appearance:none){
.navbar-menu .title-link-wrapper .link-text:after{background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='15' viewBox='0 0 23 15' fill='none'%3E%3Cpath d='M15.6847 14.7955L14.0369 13.1548L18.3622 8.83665H0.5V6.43608H18.3622L14.0369 2.1108L15.6847 0.477273L22.8438 7.63636L15.6847 14.7955Z' fill='%23005666'/%3E%3C/svg%3E") center/contain no-repeat}
}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1199.98px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 991.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 576px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 575.98px){

@supports (-webkit-appearance:none){

}

}

 @media (prefers-reduced-motion: reduce){

@supports (-webkit-appearance:none){

}

}
