
.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &[type="file"] {
    overflow: hidden; // prevent pseudo element button overlap

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    //color: $input-focus-color;
    //background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $focus-visible;
  }

  &::-webkit-date-and-time-value {
    // On Android Chrome, form-control's "width: 100%" makes the input width too small
    // Tested under Android 11 / Chrome 89, Android 12 / Chrome 100, Android 13 / Chrome 109
    //
    // On iOS Safari, form-control's "appearance: none" + "width: 100%" makes the input width too small
    // Tested under iOS 16.2 / Safari 16.2
    min-width: 85px; // Seems to be a good minimum safe width

    // Add some height to date inputs on iOS
    // https://github.com/twbs/bootstrap/issues/23307
    // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
    // Multiply line-height by 1em if it has no unit
    height: if(unit($input-line-height) == "", $input-line-height * 1em, $input-line-height);

    // Android Chrome type="date" is taller than the other inputs
    // because of "margin: 1px 24px 1px 4px" inside the shadow DOM
    // Tested under Android 11 / Chrome 89, Android 12 / Chrome 100, Android 13 / Chrome 109
    margin: 0;
  }

  // Prevent excessive date input height in Webkit
  // https://github.com/twbs/bootstrap/issues/34433
  &::-webkit-datetime-edit {
    display: block;
    padding: 0;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  // File input buttons theming
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    @include gradient-bg($form-file-button-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    @include transition($btn-transition);
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $form-file-button-hover-bg;
  }
}


.form-label {
  margin-bottom: $form-label-margin-bottom;
  @include font-size($form-label-font-size);
  //font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
}

.form-group {
  margin-bottom: 1.5rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: $font-size-base * $line-height-base;
  //padding-left: $custom-control-gutter + $custom-control-indicator-size;
  color-adjust: exact; // Keep themed appearance for print
  margin-bottom: 1.5rem;

  &:hover {
    .custom-control-input {
      &:not(:disabled) ~ .custom-control-label {
        &::before {
          box-shadow: 0 0 0 6px $primary;
          background-color: $primary;
        }
      }
    }
  }
  .custom-control-input {
    &:not(:disabled) {
      &:focus-visible {
        ~ .custom-control-label {
          &::before {
            box-shadow: $focus-visible;
          }
        }
      }
    }
  }
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    width: $custom-control-indicator-size;
    height: calc(($font-size-base * $line-height-base + $custom-control-indicator-size) / 2);
    opacity: 0;

    &:checked ~ .custom-control-label::before {
        color: $custom-control-indicator-checked-color;
        border-color: $primary-dark;
        //background-color: $primary-dark;
    }

    &:focus ~ .custom-control-label::before {
        //box-shadow: $custom-control-indicator-focus-box-shadow;
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
        //border-color: rgba($primary-dark,.5);
        border-color: $primary-dark;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
        color: $custom-control-indicator-active-color;
        background-color: $custom-control-indicator-active-bg;
        border-color: $custom-control-indicator-active-border-color;
        @include box-shadow($custom-control-indicator-active-box-shadow);
    }

    // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
    &[disabled],
    &:disabled {
        ~ .custom-control-label {
            color: $custom-control-label-disabled-color;

            &::before {
                background-color: $custom-control-indicator-disabled-bg;
            }
        }
    }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
    position: relative;
    padding-left: $custom-control-gutter + $custom-control-indicator-size;
    margin-bottom: 0;
    color: $custom-control-label-color;
    vertical-align: top;
    cursor: $custom-control-cursor;

    // Background-color and (when enabled) gradient
    &::before {
        position: absolute;
        top: calc(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2);
        left: 0;
        //left: 0;
        display: block;
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        pointer-events: none;
        content: "";
        background-color: $custom-control-indicator-bg;
        border: $custom-control-indicator-border-color solid 2px;
        @include box-shadow($custom-control-indicator-box-shadow);
        transition: box-shadow $tr-anim-xs;
    }

    // Foreground (icon)
    &::after {
        position: absolute;
        top: calc(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2);
        left: 0;
        //left: 0;
        display: block;
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        content: "";
        background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
    }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
    .custom-control-label::before {
        @include border-radius($custom-checkbox-indicator-border-radius);
    }

    .custom-control-input:checked ~ .custom-control-label {
        color: $primary-dark;
        &::after {
            //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.6667 1.5L4.25004 7.91667L1.33337 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
            //background-image: escape-svg($custom-checkbox-indicator-icon-checked);
            background: escape-svg($custom-checkbox-indicator-icon-checked) 50% / #{$custom-control-indicator-bg-size} no-repeat;
        }
    }

    .custom-control-input:indeterminate ~ .custom-control-label {
        &::before {
            border-color: $custom-checkbox-indicator-indeterminate-border-color;
            @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
            @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
        }
        &::after {
            background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate);
        }
    }

    .custom-control-input:disabled {
        &:checked ~ .custom-control-label::before {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
            border-color: rgba($primary-dark,.4);
        }
        &:indeterminate ~ .custom-control-label::before {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
        }
    }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
    .custom-control-label::before {
        // stylelint-disable-next-line property-disallowed-list
        border-radius: $custom-radio-indicator-border-radius;
    }

    .custom-control-input:checked ~ .custom-control-label {
        color: $primary-dark;
        &::after {
            //background-image: escape-svg($custom-radio-indicator-icon-checked);
            background: escape-svg($custom-radio-indicator-icon-checked) 50% / #{$custom-control-indicator-bg-size} no-repeat;;
            //background-color: transparent;
        }
    }

    .custom-control-input:disabled {
        &:checked ~ .custom-control-label::before {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
            border-color: rgba($primary-dark,.4);
        }
    }
}
.form-select {
  display: block;
  width: 100%;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
  font-family: $form-select-font-family;
  @include font-size($form-select-font-size);
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: $form-select-color;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid $form-select-border-color;
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  @include transition($form-select-transition);
  appearance: none;

  &:focus {
    border-color: $form-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &:focus-visible {
    box-shadow: $focus-visible;
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }
}

.form-select-sm {
  padding-top: $form-select-padding-y-sm;
  padding-bottom: $form-select-padding-y-sm;
  padding-left: $form-select-padding-x-sm;
  @include font-size($form-select-font-size-sm);
  @include border-radius($form-select-border-radius-sm);
}

.form-select-lg {
  padding-top: $form-select-padding-y-lg;
  padding-bottom: $form-select-padding-y-lg;
  padding-left: $form-select-padding-x-lg;
  @include font-size($form-select-font-size-lg);
  @include border-radius($form-select-border-radius-lg);
}




//.custom-select {
//select.form-control {
//    width: 100%;
//    height: 60px;
//    //background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.999999L7 7L13 1' stroke='%23201E1E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") right 24px center / 14px 8px no-repeat;
//    background: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99999 6.64996C5.88333 6.64996 5.77099 6.63329 5.66299 6.59996C5.55433 6.56663 5.45833 6.49996 5.37499 6.39996L0.849994 1.89996C0.71666 1.74996 0.649994 1.57496 0.649994 1.37496C0.649994 1.17496 0.724994 0.99996 0.874994 0.84996C1.02499 0.716627 1.19999 0.64996 1.39999 0.64996C1.59999 0.64996 1.77499 0.716627 1.92499 0.84996L5.99999 4.92496L10.1 0.84996C10.2333 0.69996 10.4 0.62896 10.6 0.63696C10.8 0.645627 10.975 0.716627 11.125 0.84996C11.275 0.99996 11.35 1.17896 11.35 1.38696C11.35 1.59563 11.275 1.76663 11.125 1.89996L6.62499 6.39996C6.54166 6.49996 6.44599 6.56663 6.33799 6.59996C6.22933 6.63329 6.11666 6.64996 5.99999 6.64996Z' fill='%23201E1E'/%3E%3C/svg%3E%0A") right 24px center / 14px 8px no-repeat;
//    appearance: none;
//
//    &:focus {
//        border-color: $primary-dark;
//        outline: 0;
//        &::-ms-value {
//            color: $input-color;
//            background-color: $input-bg;
//        }
//    }
//
//    &[multiple],
//    &[size]:not([size="1"]) {
//        height: auto;
//        padding-right: $custom-select-padding-x;
//        background-image: none;
//    }
//
//    &:disabled {
//        color: $custom-select-disabled-color;
//        background-color: $custom-select-disabled-bg;
//    }
//
//    // Hides the default caret in IE11
//    &::-ms-expand {
//        display: none;
//    }
//
//    // Remove outline from select box in FF
//    &:-moz-focusring {
//        color: transparent;
//        text-shadow: 0 0 0 $custom-select-color;
//    }
//}



//.custom-select {
//  display: inline-block;
//  width: 100%;
//  height: $custom-select-height;
//  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
//  font-family: $custom-select-font-family;
//  @include font-size($custom-select-font-size);
//  font-weight: $custom-select-font-weight;
//  line-height: $custom-select-line-height;
//  color: $custom-select-color;
//  vertical-align: middle;
//  background: $custom-select-bg $custom-select-background;
//  border: $custom-select-border-width solid $custom-select-border-color;
//  @include border-radius($custom-select-border-radius, 0);
//  @include box-shadow($custom-select-box-shadow);
//  appearance: none;
//
//  &:focus {
//    border-color: $custom-select-focus-border-color;
//    outline: 0;
//    @if $enable-shadows {
//      @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
//    } @else {
//      // Avoid using mixin so we can pass custom focus shadow properly
//      box-shadow: $custom-select-focus-box-shadow;
//    }
//
//    &::-ms-value {
//      // For visual consistency with other platforms/browsers,
//      // suppress the default white text on blue background highlight given to
//      // the selected option text when the (still closed) <select> receives focus
//      // in IE and (under certain conditions) Edge.
//      // See https://github.com/twbs/bootstrap/issues/19398.
//      color: $input-color;
//      background-color: $input-bg;
//    }
//  }
//
//  &[multiple],
//  &[size]:not([size="1"]) {
//    height: auto;
//    padding-right: $custom-select-padding-x;
//    background-image: none;
//  }
//
//  &:disabled {
//    color: $custom-select-disabled-color;
//    background-color: $custom-select-disabled-bg;
//  }
//
//  // Hides the default caret in IE11
//  &::-ms-expand {
//    display: none;
//  }
//
//  // Remove outline from select box in FF
//  &:-moz-focusring {
//    color: transparent;
//    text-shadow: 0 0 0 $custom-select-color;
//  }
//}
//
//.custom-select-sm {
//  height: $custom-select-height-sm;
//  padding-top: $custom-select-padding-y-sm;
//  padding-bottom: $custom-select-padding-y-sm;
//  padding-left: $custom-select-padding-x-sm;
//  @include font-size($custom-select-font-size-sm);
//}
//
//.custom-select-lg {
//  height: $custom-select-height-lg;
//  padding-top: $custom-select-padding-y-lg;
//  padding-bottom: $custom-select-padding-y-lg;
//  padding-left: $custom-select-padding-x-lg;
//  @include font-size($custom-select-font-size-lg);
//}