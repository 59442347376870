:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.subscribe-section {
  color: #fff;
  background-color: #993366;
  padding: 32px 0;
}
.subscribe-section .section-title {
  color: inherit;
  margin-bottom: 48px;
}
.subscribe-section .section-text {
  margin-bottom: 3rem;
}
.subscribe-section .img-holder {
  text-align: center;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}
.subscribe-section .col-text .img-holder {
  margin-bottom: 1.75rem;
}
@media (max-width: 767.98px) {
  .subscribe-section .col-img {
    display: none;
  }
  .subscribe-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .subscribe-section {
    padding: 64px 0;
  }
  .subscribe-section .section-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
  }
  .subscribe-section .col-text,
  .subscribe-section .col-img {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 0;
  }
  .subscribe-section .col-text .img-holder {
    display: none;
  }
  .subscribe-section .section-title {
    font-size: 32px;
    line-height: 1.4;
    letter-spacing: 0;
  }
  .home .subscribe-section .section-title {
    font-size: 32px;
  }
  .subscribe-section .section-text {
    font-size: 20px;
    line-height: 1.6;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}
