:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

@media (min-width: 768px) {
  .error404 .join-section {
    padding: 108px 0;
  }
}

.hero-404-section {
  background-color: #fff;
  padding: 40px 0;
}
.hero-404-section .hero-title {
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: 40px;
}
.hero-404-section .hero-text {
  margin-bottom: 1.5rem;
}
.hero-404-section .col-img .img-holder {
  text-align: center;
}
@media (max-width: 767.98px) {
  .hero-404-section .col-img {
    display: none;
  }
  .hero-404-section .hero-title {
    font-size: 48px;
  }
}
@media (min-width: 768px) {
  .hero-404-section {
    padding: 120px 0;
  }
  .hero-404-section .section-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
  }
  .hero-404-section .col-text,
  .hero-404-section .col-img {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 0;
  }
  .hero-404-section .col-img .img-holder {
    text-align: right;
  }
  .hero-404-section .hero-title {
    margin-bottom: 64px;
  }
  .hero-404-section .hero-text {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 0;
  }
}
@supports (-webkit-appearance:none){

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}
